import React, { useEffect, useRef, useState } from 'react';
import { Field, Formik } from 'formik';
import * as yup from 'yup';
import i18next from 'i18next';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import SharedInputText from '../../components/Shared/SharedInputText';
import SharedButton from '../../components/Shared/SharedButton';
import { createInvoice, getInvoicesList, updatePatientInvoice } from '../../store/slices/InvoicesSlice';
import SharedDropdown from '../../components/Shared/SharedDropdown';
import CustomSearchDropdown from '../../components/Shared/CustomSearchDropdown';
import { getPriceListByID } from '../../store/slices/ItemSlice';
import { getCurrentDateTime } from '../Transactions/CreateTransaction';
import CustomSearchInput from '../../components/Shared/CustomSearchInput';
import { getCashBankAccounts } from '../../store/slices/AcountsSlice';

function CalculateTax(formikProps) {
  const items = Array.isArray(formikProps?.values?.items) ? formikProps?.values?.items : [];

  const taxableAmount = items
    .filter(itm => itm?.tax_check)
    .map(itm => {
      const unitPrice = Number(itm?.unit_price) || 0;
      const quantity = Number(itm?.quantity) || 0;
      return unitPrice * quantity;
    })
    .reduce((acc, curr) => acc + curr, 0);

  const totalDiscount = items
    .filter(itm => itm?.tax_check)
    .map(itm => {
      const discountAmount = Number(itm?.discount_amount) || 0;
      const discountType = itm?.discount_type;
      let discount = 0;

      if (discountType === 'percentage') {
        discount = (Number(itm.unit_price) * Number(itm.quantity) * discountAmount) / 100;
      } else if (discountType === 'fixed') {
        discount = discountAmount;
      }

      return discount;
    })
    .reduce((acc, curr) => acc + curr, 0);

  const taxableAmountAfterDiscount = Math.max(taxableAmount - totalDiscount, 0);

  const taxAmount = (taxableAmountAfterDiscount * 15) / 100;

  return taxAmount;
}

const calculateCashDetails = formikProps => {
  const items = Array.isArray(formikProps?.values?.items) ? formikProps?.values?.items : [];

  const detailedItems = items.map(itm => {
    const unitPrice = Number(itm.unit_price) || 0;
    const quantity = parseFloat(itm.quantity) || 0;

    const itemAmount = unitPrice * quantity;

    const discountAmount = Number(itm?.discount_amount) || 0;
    const discountType = itm?.discount_type || '';
    let itemDiscount = 0;

    if (discountType === 'percentage') {
      itemDiscount = (unitPrice * quantity * discountAmount) / 100;
    } else if (discountType === 'fixed') {
      itemDiscount = discountAmount;
    }

    const itemTax = itm.tax_check ? CalculateTax({ values: { items: [itm] } }) : 0;

    const itemNetTotal = itemAmount - itemDiscount + itemTax;

    return {
      discount_amount: Number(discountAmount),
      tax_check: Boolean(itm?.tax_check),
      unitPrice,
      quantity: Number(quantity),
      item_code: itm?.item_code || '',
      item_name: itm?.item_name || '',
      item_type: itm?.item_type || '',
      discount_type: discountType,
      discountAmount: Number(itemDiscount.toFixed(2)),
      discountedAmount: parseFloat((itemAmount - itemDiscount).toFixed(2)),
      itemAmount: parseFloat(itemAmount.toFixed(2)),
      patientShare: parseFloat(itemNetTotal.toFixed(2)),
      companyShare: 0,
      patientTax: parseFloat(itemTax.toFixed(2)),
      companyTax: 0
    };
  });

  const totalAmount = detailedItems.map(itm => itm.itemAmount).reduce((acc, curr) => acc + curr, 0);

  const totalDiscount = detailedItems.map(itm => itm.discountAmount).reduce((acc, curr) => acc + curr, 0);

  const totalTax = detailedItems.map(itm => itm.patientTax).reduce((acc, curr) => acc + curr, 0);

  const netTotal = detailedItems.map(itm => itm.patientShare).reduce((acc, curr) => acc + curr, 0);

  return {
    itemBreakdown: detailedItems,
    totals: {
      patientShareTotal: parseFloat(totalAmount.toFixed(2)),
      companyShareTotal: 0,
      patientTaxTotal: parseFloat(totalTax.toFixed(2)),
      companyTaxTotal: 0,
      discountedAmount: parseFloat(totalDiscount.toFixed(2)),
      totalAmount: parseFloat((totalAmount - totalDiscount + totalTax).toFixed(2))
    }
  };
};

export default function CreateInvoicePage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const lang = i18next.language;
  const [deletedItems, setDeletedItems] = useState([]);
  const formikRef = useRef(null);

  const { isLoading, invocieDetail } = useSelector(state => state.invoice);
  const { items } = useSelector(state => state.item);
  const { user } = useSelector(state => state.auth);
  const { cashBankAccounts } = useSelector(state => state?.acount);

  const [validate, setValidate] = useState('');
  const location = useLocation();
  const cancelData = location?.state?.data;
  const cancelStatus = location?.state?.cancel;

  const paid_values = [
    { value: true, label: 'Paid' },
    { value: false, label: 'Unpaid' }
  ];
  const payment_method_values = [
    { value: 'cash', label: lang === 'ar' ? 'نقدي' : 'Cash' },
    { value: 'bank', label: lang === 'ar' ? 'بنك' : 'Bank' },
    { value: 'mada', label: lang === 'ar' ? 'مدى' : 'MADA' },
    { value: 'cheque', label: lang === 'ar' ? 'يفحص' : 'Cheque' }
  ];

  const customersOptionsFromat = cutomersData => {
    let options = cutomersData.map(customer => {
      return {
        value: Number(customer?.id),
        label: customer?.name + ' ' + customer?.secondary_contact_number
      };
    });
    return options;
  };

  useEffect(() => {
    dispatch(getPriceListByID({ payer_id: '0' }));
    dispatch(getCashBankAccounts());
  }, []);

  function itemsOptions(inputData) {
    return Object.values(inputData || {}).map(item => ({
      value: item?.ID,
      label: lang === 'ar' ? item.name_ar : item.name_en,
      amount: item?.price,
      discount: item?.discount,
      item_code: item?.item_code,
      item_type: item?.item_type,
      item_name: item?.name_ar
    }));
  }

  const ItemsOptions = itemsOptions(items?.data || []);

  const listAccountsvalues = cashBankAccounts?.data
    ? Object.entries(cashBankAccounts.data).map(([id, account]) => ({
        id: Number(account?.code),
        name: lang === 'ar' ? account.name_ar : account.name_en
      }))
    : [];

  const initialValues = {
    date: cancelData ? cancelData?.date : getCurrentDateTime(),
    // amount: 0,
    invoice_ref: cancelData ? cancelData?.invoice_number : 0,
    description: cancelData ? cancelData?.description : '',
    paid: cancelData ? cancelData?.paid : false,
    payment_method: cancelData ? cancelData?.payment_method : '',
    customer_id: cancelData ? cancelData?.customer_id : '',
    branch_id: cancelData ? cancelData?.branch_id : user?.branch_id,
    business_id: cancelData ? cancelData?.business_id : user?.business_id,
    invoice_type: 'sales',
    items: cancelData
      ? invocieDetail?.map(data => {
          return { ...data, item_name: data?.item_name_en };
        })
      : [],
    account_code: cancelData ? cancelData?.account_code : ''
  };

  const validationSchema = yup.object().shape({
    date: yup.string().required(t('required')),
    invoice_type: yup.string().required(t('required')),
    description: yup.string().required(t('required')),
    payment_method: yup.string().when('paid', {
      is: true,
      then: () => yup.string().required(t('required')),
      otherwise: () => yup.string().notRequired()
    }),
    customer_id: yup.number().required(t('required')),
    account_code: yup.string().when('paid', {
      is: true,
      then: () => yup.string().required(t('required')),
      otherwise: () => yup.string().notRequired()
    }),
    items: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.number().required(t('required'))
        })
      )
      .min(1, t('required'))
  });

  const getTotalAmount = formikProps => {
    return formikProps?.values?.items.reduce(
      (acc, transaction) => acc + Number(transaction?.quantity) * Number(transaction?.amount),
      0
    );
  };

  const getItemName = item => {
    if (item?.item_name) return item.item_name;
    return lang === 'ar' ? item?.item_name_ar : item?.item_name_en;
  };

  const getInvoiceText = () => {
    if (cancelData) return cancelStatus ? t('cancel') : t('update');
    return t('create');
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        innerRef={formikRef}
        onSubmit={async (data, { resetForm }) => {
          // delete account code key if paid is false
          if (!data?.paid) {
            delete data.account_code;
          }
          if (cancelData) {
            dispatch(
              updatePatientInvoice({
                branch_id: data?.branch_id,
                business_id: data?.business_id,
                customer_id: data?.customer_id,
                date: data?.date,
                invoice_ref: data?.invoice_ref,
                description: data?.description,
                invoice_type: data?.invoice_type,
                ...(formikRef?.current?.values?.paid === true && {
                  account_code: data?.account_code
                }),
                deleted_items: deletedItems.flat(Infinity),
                item_details: calculateCashDetails(formikRef?.current),
                paid: data?.paid,
                payment_method: data?.payment_method
              })
            ).then(() => {
              resetForm();
              navigate('/manager/creditInvoiceTable');
              dispatch(getInvoicesList({ page: 1, per_page: 10 }));
            });
          } else {
            const resultAction = await dispatch(
              createInvoice({
                branch_id: data?.branch_id,
                business_id: data?.business_id,
                customer_id: data?.customer_id,
                date: data?.date,
                description: data?.description,
                invoice_type: data?.invoice_type,
                ...(formikRef?.current?.values?.paid === true && {
                  account_code: data?.account_code
                }),
                item_details: calculateCashDetails(formikRef?.current),
                paid: data?.paid,
                payment_method: data?.payment_method
              })
            );
            if (createInvoice.fulfilled.match(resultAction)) {
              resetForm();
              navigate('/manager/invoices');
            }
          }
        }}
        validateOnChange={true}
        validateOnBlur={true}
        validate={values => {
          validationSchema
            .validate(values, { abortEarly: false })
            .then(() => console.log('Validation passed'))
            .catch(err => console.log('Validation errors:', err.errors));
        }}
      >
        {props => (
          <>
            <div className='flex rounded-md bg-gray-100 p-6 w-full gap-4 max-h-[calc(100vh-150px)]'>
              <div className={` ${props?.values?.items?.length > 0 ? 'w-[70%]' : 'w-full'}`}>
                <h2 className='text-3xl font-bold mb-6 text-gray-800'>{`${getInvoiceText()} ${t('salesinvoice')}`}</h2>

                <div className='grid grid-cols-1 md:grid-cols-4 gap-4 w-full mb-3'>
                  <SharedInputText
                    type={'datetime-local'}
                    label={t('date')}
                    name={'date'}
                    placeholder='Enter Date'
                    className='!p-1.5'
                  />

                  <CustomSearchInput
                    name='customer_id'
                    placeholder={`${t('select')} ${t('customers')}`}
                    label={t('customers')}
                    className={'!w-full'}
                    isAsync={true}
                    fieldUrl={'searchCustomer'}
                    optionsFormat={customersOptionsFromat}
                    defaultValue={
                      cancelData ? { label: cancelData?.customer_name, value: cancelData?.customer_id } : ''
                    }
                  />

                  <SharedDropdown label={t('payment_method')} name={'payment_method'} options={payment_method_values} />
                  <SharedDropdown label={t('paid')} name={'paid'} options={paid_values} />
                  {props?.values?.paid === true && (
                    <CustomSearchDropdown
                      label={t('account')}
                      className='!w-full'
                      name={'account_code'}
                      options={listAccountsvalues}
                      placeholder={`${t('select')} ${t('account')}`}
                    />
                  )}
                  <SharedInputText
                    label={t('description')}
                    name={'description'}
                    placeholder={`${t('enter')} ${t('description')}`}
                    className='!p-1.5'
                  />
                </div>
                <div className='max-h-[450px] overflow-y-auto scrollbar'>
                  {props?.values?.items?.map((item, index) => (
                    <div key={index} className='grid grid-cols-1 gap-2 mb-4 border-t border-b py-4'>
                      <div className='flex gap-4 items-center'>
                        <div className='flex'>
                          <span className='font-semibold'>{t('name')}: </span>
                          <div className='px-2'>{getItemName(item)}</div>
                        </div>
                        <div className='flex items-center'>
                          <span className='font-semibold'>{t('ItemAmount')}: </span>
                          <div className='px-2'>
                            {/* {getAmount(item)} */}
                            <Field
                              name={`items[${index}].unit_price`}
                              as={SharedInputText}
                              type='number'
                              min={1}
                              placeholder={`${t('enter')}${t('amount')}`}
                              className='!p-0.5'
                              value={item.amount ? item?.amount : item?.unit_price}
                              onChange={e => {
                                const newItems = [...props.values.items];
                                newItems[index].unit_price = Number(e.target.value);
                                props.setFieldValue('items', newItems);
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className='grid grid-cols-5 gap-4'>
                        <Field
                          name={`items[${index}].quantity`}
                          as={SharedInputText}
                          label={t(`quantity`)}
                          type='number'
                          placeholder={`${t('enter')}${t('quantity')}`}
                          min={1}
                          className='!p-1.5'
                          value={item.quantity === 0 ? '' : item.quantity}
                          onChange={e => {
                            const newValue = e.target.value === '' ? '' : Number(e.target.value);
                            const newItems = [...props.values.items];
                            newItems[index].quantity = newValue;
                            props.setFieldValue('items', newItems);
                          }}
                        />
                        <div className='flex flex-col justify-between'>
                          <label className='text-base font-medium'>{t('discountType')}</label>
                          <div className='flex w-full'>
                            <button
                              type='button'
                              onClick={() => {
                                props.setFieldValue(`items[${index}].discount_type`, 'percentage');
                              }}
                              className={`w-[50%] border !p-1.5 transition-all rounded-tl-md rounded-bl-md !border-r-0 ${
                                props.values.items[index].discount_type === 'percentage'
                                  ? '!bg-primary !border-primary text-white'
                                  : '!bg-white !border-white text-primary'
                              }`}
                            >
                              {t('percent')}
                            </button>
                            <button
                              type='button'
                              onClick={() => {
                                props.setFieldValue(`items[${index}].discount_type`, 'fixed');
                              }}
                              className={`w-[50%] border !p-1.5 !border-l-0 transition-all rounded-tr-md rounded-br-md ${
                                props.values.items[index].discount_type === 'fixed'
                                  ? '!bg-primary !border-primary text-white'
                                  : '!bg-white !border-white text-primary'
                              }`}
                            >
                              {t('fix')}
                            </button>
                          </div>
                        </div>
                        <Field
                          name={`items[${index}].discount_amount`}
                          as={SharedInputText}
                          label={t('discountAmount')}
                          type='number'
                          placeholder={`${t('enter')}${t('amount')}`}
                          className='!p-1.5'
                          min={0}
                          value={item.discount_amount === 0 ? 0 : item.discount_amount}
                          onChange={e => {
                            const newValue = e.target.value === 0 ? 0 : Number(e.target.value);
                            const newItems = [...props.values.items];
                            newItems[index].discount_amount = newValue;
                            props.setFieldValue('items', newItems);
                          }}
                        />
                        <div className='flex flex-col justify-between'>
                          <label className='text-base font-medium'>{t('tax')} %</label>
                          <div className='flex w-full'>
                            <button
                              type='button'
                              onClick={() => {
                                props.setFieldValue(`items[${index}].tax_check`, false);
                              }}
                              className={`w-[50%] border !p-1.5 transition-all rounded-tl-md rounded-bl-md !border-r-0 ${
                                props.values.items[index].tax_check === false
                                  ? '!bg-primary !border-primary text-white'
                                  : '!bg-white !border-white text-primary'
                              }`}
                            >
                              {t('false')}
                            </button>
                            <button
                              type='button'
                              onClick={() => {
                                props.setFieldValue(`items[${index}].tax_check`, true);
                              }}
                              className={`w-[50%] border !p-1.5 !border-l-0 transition-all rounded-tr-md rounded-br-md ${
                                props.values.items[index].tax_check === true
                                  ? '!bg-primary !border-primary text-white'
                                  : '!bg-white !border-white text-primary'
                              }`}
                            >
                              {t('true')}
                            </button>
                          </div>
                        </div>

                        <div className='flex justify-end items-end'>
                          <span className='h-[30px] w-full flex justify-end pr-5'>
                            <RiDeleteBin5Line
                              size={20}
                              className='cursor-pointer text-red-500'
                              onClick={() => {
                                if (cancelStatus) {
                                  const newItem = props.values.items.filter((_, i) => i === index);
                                  setDeletedItems(prev => [...prev, newItem]);
                                }
                                const newItems = props.values.items.filter((_, i) => i !== index);
                                props.setFieldValue('items', newItems);
                              }}
                            />
                          </span>
                        </div>
                      </div>

                      {props.errors.items && props.errors.items[index] && (
                        <div className='col-span-4 text-red-500'>
                          {Object.values(props.errors.items[index]).map((error, i) => (
                            <div key={i}>{error}</div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                  {/* {props.errors.items && typeof props.errors.items === 'string' && (
                    <div className='text-red-500'>{props.errors.items}</div>
                  )} */}
                </div>

                <div className='mt-4 '>
                  <CustomSearchInput
                    label={t('items')}
                    name={'items'}
                    options={ItemsOptions}
                    placeholder={`${t('select')} ${t('items')}`}
                    className='!w-full'
                    onChange={selectedOption => {
                      const newItem = {
                        id: selectedOption.value,
                        patientShare: 0,
                        unit_price: Number(selectedOption.amount),
                        quantity: 1,
                        discount_type: 'percentage',
                        discount_amount: Number(selectedOption?.discount) || 20,
                        tax_type: 'percent',
                        tax_amount: 15,
                        tax_check: false,
                        item_code: selectedOption?.item_code,
                        item_type: selectedOption?.item_type,
                        item_name: selectedOption?.label
                      };
                      props.setFieldValue('items', [...props.values.items, newItem]);
                    }}
                  />
                </div>

                <div className='flex justify-between mt-6'>
                  <div>
                    {!cancelStatus && (
                      <SharedButton
                        icon='pi pi-plus'
                        label={t('CREATECUSTOMERS')}
                        className='submit-btn'
                        onClick={() => navigate('/manager/customers')}
                      />
                    )}
                  </div>

                  <div className='flex gap-6'>
                    <SharedButton
                      icon='pi pi-times'
                      label={t('Cancel')}
                      className='cancel-btn'
                      onClick={() => {
                        navigate('/manager/invoices');
                        props.resetForm();
                      }}
                    />
                    <SharedButton
                      icon='pi pi-check'
                      label={t(cancelData ? 'Update' : 'Submit')}
                      className='submit-btn'
                      disabled={isLoading}
                      onClick={props.handleSubmit}
                    />
                  </div>
                </div>
              </div>

              <div className={`${props?.values?.items?.length > 0 ? 'w-[30%]' : 'hidden'}`}>
                {props?.values?.items?.length > 0 && (
                  <div className='mt-6 bg-white rounded-lg shadow-md p-6'>
                    <h3 className='text-2xl font-semibold mb-4 text-gray-800'>{t('BillSummary')}</h3>
                    <div className='space-y-4'>
                      <div className='bg-gray-50 p-4 !pb-2 rounded-lg'>
                        <h4 className='text-lg font-semibold mb-4 text-gray-700 text-center'>{t('YourTotalBill')}</h4>
                        <div className='space-y-2'>
                          {/* Total Amount */}
                          <div className='flex justify-between'>
                            <span className='text-gray-600'>{`${t('Total')} ${t('amount')}`}:</span>
                            <span className='font-semibold'>
                              {calculateCashDetails(props)?.totals?.patientShareTotal}
                            </span>
                          </div>

                          {/* Total Discount */}
                          <div className='flex justify-between'>
                            <span className='text-gray-600'>{`${t('Total')} ${t('discount')}`}:</span>
                            <span className='font-semibold'>
                              {calculateCashDetails(props)?.totals?.discountedAmount}
                            </span>
                          </div>

                          {/* Total VAT */}
                          <div className='flex justify-between'>
                            <span className='text-gray-600'>{`${t('Total')} ${t('vat')}`}:</span>
                            <span className='font-semibold'>
                              {calculateCashDetails(props)?.totals?.patientTaxTotal}
                            </span>
                          </div>

                          {/* Final Total (Net Amount) */}
                          <div className='flex justify-between text-lg font-semibold mt-4'>
                            <span className='text-gray-600'>{t('total')}:</span>
                            <span>{calculateCashDetails(props)?.totals?.totalAmount}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </Formik>
    </>
  );
}
