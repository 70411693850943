import React, { useEffect, useRef, useState } from 'react';
import * as XLSX from 'xlsx';
import { Menu } from 'primereact/menu';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SharedButton from '../../components/Shared/SharedButton';
import CashFlowCard from './CashFlowCard';
import { getCashBankFlow} from '../../store/slices/ReportSlice';
import BankFlowCard from './BankFlowCard';

export const getOneMonthAgo = currentDate => {
  const date = new Date(currentDate);
  date.setMonth(date.getMonth() - 1);
  return date.toISOString().split('T')[0];
};

export default function CashInflow() {
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
  const [startDate, setStartDate] = useState(getOneMonthAgo(endDate));
  const { loading,cashBankFlow } = useSelector(state => state.reports);
  const dispatch = useDispatch();
  const lang = i18next.language;
  const { t } = useTranslation();
  const balanceSheetRef = useRef(null);
  const iframeRef = useRef(null);
  const menuRef = useRef(null);
  const handleSubmit = () => {
    if (startDate && endDate) {
      const payload = {
        start_date: startDate,
        end_date: endDate
      };
      dispatch(getCashBankFlow(payload));
    }
  };

  useEffect(() => {
    const payload = {
      start_date: startDate,
      end_date: endDate
    };
    dispatch(getCashBankFlow(payload));
  }, []);

  const handlePrint = () => {
    const direction = lang == 'ar' ? 'rtl' : 'ltr';
    const printContents = balanceSheetRef.current.innerHTML;
    const iframe = iframeRef.current;
    const doc = iframe.contentWindow.document;
    doc.open();
    doc.write('<html><head><title>Balance Sheet</title>');
    doc.write('<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css">');

    doc.write('<style>');
    doc.write(`
      @media print {
        @page {
          margin: 20px 0 20px 0; /* Removes the default margins */
        }
        body {
          margin: 1cm; /* Adjust the margins to your needs */
           direction: ${direction};
        }
        /* Hide browser print headers and footers */
        header, footer {
          display: none;
        }
        table {
          direction: ${direction};
        }
      }
    `);
    doc.write('</style>');

    doc.write('</head><body>');
    doc.write(printContents);
    doc.write('</body></html>');
    doc.close();

    iframe.onload = () => {
      iframe.contentWindow.print();
    };
  };

  const extractData = element => {
    const data = [];
    const cards = element.querySelectorAll('.bg-white.rounded-lg.shadow-md');

    cards.forEach(card => {
      const title = card.querySelector('h2').textContent;
      data.push([title]);
      data.push(['Item', 'Amount']);

      const items = card.querySelectorAll('.flex.justify-between');
      items.forEach(item => {
        const name = item.querySelector('.font-medium').textContent.replace(':', '');
        const amount = item.querySelector('span:last-child').textContent;
        data.push([name, amount]);
      });

      data.push([]); // Empty row for spacing between cards
    });

    return data;
  };

  const handleExcelExport = () => {
    const wb = XLSX.utils.book_new();
    const data = extractData(balanceSheetRef.current);
    const ws = XLSX.utils.aoa_to_sheet(data);

    // Set column widths
    const colWidths = [{ wch: 30 }, { wch: 20 }];
    ws['!cols'] = colWidths;

    XLSX.utils.book_append_sheet(wb, ws, 'Cash Flow Statement');
    XLSX.writeFile(wb, 'Cash Flow Statement.xlsx');
  };

  const items = [
    {
      label: t('EXCEL'),
      icon: 'pi pi-file-excel',
      command: handleExcelExport
    },
    {
      label: t('PDF'),
      icon: 'pi pi-print',
      command: handlePrint
    }
  ];

  return (
    <div>
      <div className='flex justify-between'>
        <div className='flex gap-5 flex-wrap'>
          <div className='flex  items-center gap-2'>
            <label className='text-sm'>{t('StartDate')}</label>
            <input
              required
              type='date'
              name='start_date'
              value={startDate}
              onChange={e => {
                setStartDate(e.target.value);
              }}
              id='dobDate'
              autoComplete='off'
              className='p-[0.33rem] border rounded-md'
            />
          </div>
          <div className='flex items-center gap-2'>
            <label className='text-sm'>{t('EndDate')}</label>
            <input
              required
              type='date'
              name='end_date'
              value={endDate}
              onChange={e => {
                setEndDate(e.target.value);
              }}
              id='dobDate'
              autoComplete='off'
              className='p-[0.33rem] border rounded-md'
            />
          </div>
          <div className=''>
            <SharedButton
              label={t('Submit')}
              className='bg-gradient primary-btn'
              onClick={handleSubmit}
              disabled={!startDate || !endDate}
            />
          </div>
        </div>

        <div className=''>
          <SharedButton
            label={`${t('Export')}`}
            icon='pi pi-download'
            iconPos='right'
            onClick={e => menuRef.current.toggle(e)}
            className='bg-gradient primary-btn'
          />
          <Menu
            pt={{
              root: { className: '!py-0 !bg-none' },
              content: { className: '!p-0 !bg-none !text-gray-500' },
              action: { className: '!py-1.5 !px-2 !bg-none !text-gray-500' },
              menuitem: { className: '!bg-none' },
              menu: { className: '!bg-gray-100' },
              icon: { className: '!text-gray-500' }
            }}
            model={items}
            popup
            ref={menuRef}
          />
        </div>
      </div>

      <div className='this_div' ref={balanceSheetRef}>
        {cashBankFlow ? (
          <>
            <CashFlowCard cashFlow={cashBankFlow} />
            <BankFlowCard bankFlow={cashBankFlow} />
          </>
        ) : null}
      </div>

      <iframe ref={iframeRef} style={{ display: 'none' }}></iframe>
    </div>
  );
}
