import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Field, Formik } from 'formik';
import * as yup from 'yup';
import SharedInputText from '../../components/Shared/SharedInputText';
import SharedButton from '../../components/Shared/SharedButton';
import { useDispatch, useSelector } from 'react-redux';
import { createCompanyInvoice, getCompanyInvoicesList, updatePatientInvoice } from '../../store/slices/InvoicesSlice';
import SharedDropdown from '../../components/Shared/SharedDropdown';
import CustomSearchDropdown from '../../components/Shared/CustomSearchDropdown';
import { getItems, getPriceListByID } from '../../store/slices/ItemSlice';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { getCustomers } from '../../store/slices/CustomerSlice';
import { GetBusinesses } from '../../store/slices/BusinessSlice';
import Loader from '../../Loader';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCurrentDateTime } from '../Transactions/CreateTransaction';
import i18next from 'i18next';
import CustomSearchInput from '../../components/Shared/CustomSearchInput';
import { getCashBankAccounts } from '../../store/slices/AcountsSlice';
import toast from 'react-hot-toast';

export default function CreateCompanyInvoicePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const formikRef = useRef(null);
  const { cashBankAccounts } = useSelector(state => state?.acount);
  const lang = i18next.language;
  const location = useLocation();
  const cancelData = location?.state?.data;
  const { user } = useSelector(state => state.auth);
  const { customers } = useSelector(state => state.customers);
  const { business } = useSelector(state => state.business);
  const { items } = useSelector(state => state.item);
  const { isLoading, companyDetail } = useSelector(state => state.invoice);
  const [maxLimit, setMaxLimit] = useState(50);
  const [license, setLicense] = useState(null);
  const cancelStatus = location?.state?.cancel;
  const [deletedItems, setDeletedItems] = useState(null);
  const [selectedCustomerData, setSelectedCustomerData] = useState(null);

  useEffect(() => {
    dispatch(getCustomers());
    dispatch(GetBusinesses());
    dispatch(getCashBankAccounts());
    dispatch(getItems({ payer_id: '1' }));
  }, [dispatch]);

  function payerOptions(inputData) {
    return Object.values(inputData || {}).map(item => ({
      license: item.company_license,
      value: item?.id,
      label: lang === 'ar' ? item.name_ar : item.name_en
    }));
  }
  const PayerOptions = payerOptions(business || []);

  function itemsOptions(inputData) {
    return Object.values(inputData || {}).map(item => ({
      value: item?.ID,
      label: lang === 'ar' ? item.name_ar : item.name_en,
      amount: item?.price,
      item_code: item?.item_code,
      item_type: item?.item_type
    }));
  }
  const ItemsOptions = itemsOptions(items || []);

  const getAmount = itemId => {
    const item = ItemsOptions.find(item => item.value === itemId);
    return item ? item.amount : 0;
  };
  const listAccountsvalues = cashBankAccounts?.data
    ? Object.entries(cashBankAccounts.data).map(([id, account]) => ({
        id: Number(account?.code),
        name: lang === 'ar' ? account.name_ar : account.name_en
      }))
    : [];

  const paid_values = [
    { value: true, label: 'Paid' },
    { value: false, label: 'Unpaid' }
  ];
  const payment_method_values = [
    { value: 'cash', label: lang === 'ar' ? 'نقدي' : 'Cash' },
    { value: 'bank', label: lang === 'ar' ? 'بنك' : 'Bank' },
    { value: 'mada', label: lang === 'ar' ? 'مدى' : 'MADA' },
    { value: 'cheque', label: lang === 'ar' ? 'يفحص' : 'Cheque' }
  ];

  const customersOptionsFromat = cutomersData => {
    let options = cutomersData.map(customer => {
      return {
        value: Number(customer?.id),
        label: customer?.name + ' ' + customer?.secondary_contact_number
      };
    });
    return options;
  };

  const initialValues = {
    date: cancelData ? cancelData?.date : getCurrentDateTime(),
    max_limit: selectedCustomerData?.limit ?? 0,
    description: cancelData ? cancelData?.description : '',
    payment_method: cancelData ? companyDetail[0]?.payment_method : '',
    paid: cancelData ? cancelData?.paid : false,
    customer_id: cancelData ? companyDetail[0]?.patient_id || '' : '',
    branch_id: cancelData ? cancelData?.branch_id : user?.branch_id,
    business_id: cancelData ? cancelData?.business_id : user?.business_id,
    invoice_type: 'credit',
    company_id: cancelData ? companyDetail[0]?.company_id : '',
    invoice_ref: cancelData ? cancelData?.invoice_number : 0,
    account_code: cancelData ? cancelData?.account_code : '',
    items: cancelData ? companyDetail : []
  };

  const validationSchema = yup.object().shape({
    date: yup.string().required(t('required')),
    description: yup.string().required(t('required')),
    payment_method: yup.string().when('paid', {
      is: true,
      then: () => yup.string().required(t('required')),
      otherwise: () => yup.string().notRequired()
    }),
    customer_id: yup.number().required(t('required')),
    company_id: yup.number().required(t('required')),
    account_code: yup.string().when('paid', {
      is: true,
      then: () => yup.string().required(t('required')),
      otherwise: () => yup.string().notRequired()
    }),
    items: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.number().required(t('required')),
          tax_amount: yup.number().min(0, 'Tax amount must be non-negative').required(t('required'))
        })
      )
      .min(1, t('required'))
  });

  const handleCompanySelect = value => {
    fetchPriceList(value);
  };
  const prevPriceListIdRef = useRef();
  const fetchPriceList = useCallback(id => {
    if (id && id !== prevPriceListIdRef.current) {
      dispatch(getPriceListByID({ payer_id: String(id?.target?.value) }));
      prevPriceListIdRef.current = id;
    }
  }, []);

  const getNationality = formik => {
    const customersArray = Object.values(customers);
    const patient = customersArray.find(item => item.id === formik?.values?.customer_id);
    return patient ? patient.nationality : null;
  };

  function calculateShares(props, maxLimit) {
    const items = props?.values?.items || [];
    let totalPatientShare = 0;
    let totalCompanyShare = 0;
    let totalPatientTax = 0;
    let totalCompanyTax = 0;
    let totalDiscountedAmount = 0;
    const nationality = getNationality(props);
    const itemBreakdown = items.map(itm => {
      const unitPrice = itm?.unit_price || 0;
      const quantity = itm?.quantity || 0;
      const discountAmount = itm?.discount_amount || 0;
      const patientSharePercentage = itm?.patient_share;

      const totalAmount = unitPrice * quantity;
      let discountedAmount;

      if (itm?.discount_type === 'fixed') {
        discountedAmount = totalAmount - discountAmount;
        totalDiscountedAmount += discountAmount;
      } else {
        const discountValue = (totalAmount * discountAmount) / 100;
        totalDiscountedAmount += discountValue;
        discountedAmount = totalAmount - discountValue;
      }

      const fullPatientShare = (discountedAmount * patientSharePercentage) / 100;
      let currentPatientShare = 0;
      let currentCompanyShare = 0;

      if (totalPatientShare < maxLimit) {
        const remainingLimit = maxLimit - totalPatientShare;
        if (fullPatientShare <= remainingLimit) {
          currentPatientShare = fullPatientShare;
        } else {
          currentPatientShare = remainingLimit;
        }
        totalPatientShare += currentPatientShare;
      }

      currentCompanyShare = discountedAmount - currentPatientShare;
      totalCompanyShare += currentCompanyShare;

      let patientTax = 0;
      let companyTax = currentCompanyShare * 0.15;

      if ((nationality === 'saudi' || nationality === 'sa') && itm?.tax_check) {
        patientTax = currentPatientShare * 0.15;
      } else if (itm?.tax_check) {
        patientTax = currentPatientShare * 0.15;
      }

      totalPatientTax += patientTax;
      totalCompanyTax += companyTax;

      return {
        discount_amount: Number(discountAmount),
        patient_share: Number(patientSharePercentage),
        tax_check: Boolean(itm?.tax_check),
        item_code: itm?.item_code || '',
        item_name: itm?.item_name || '',
        item_type: itm?.item_type || '',
        discount_type: itm?.discount_type,
        unitPrice,
        quantity: Number(quantity),
        totalAmount: parseFloat(totalAmount.toFixed(2)),
        discountAmount: parseFloat(discountAmount),
        discountedAmount: parseFloat(discountedAmount.toFixed(2)),
        patientShare: parseFloat(currentPatientShare.toFixed(2)),
        companyShare: parseFloat(currentCompanyShare.toFixed(2)),
        patientTax: parseFloat(patientTax.toFixed(2)),
        companyTax: parseFloat(companyTax.toFixed(2))
      };
    });

    return {
      itemBreakdown,
      totals: {
        patientShareTotal: parseFloat(totalPatientShare.toFixed(2)),
        companyShareTotal: parseFloat(totalCompanyShare.toFixed(2)),
        patientTaxTotal: parseFloat(totalPatientTax.toFixed(2)),
        companyTaxTotal: parseFloat(totalCompanyTax.toFixed(2)),
        discountedAmount: Number(totalDiscountedAmount),
        totalAmount: parseFloat((totalPatientShare + totalCompanyShare + totalPatientTax + totalCompanyTax).toFixed(0))
      }
    };
  }
  useEffect(() => {
    const cs = business?.find(customer => {
      return customer?.company_license == companyDetail[0]?.company_id;
    });
    setLicense(cs?.company_license);
    formikRef?.current?.setFieldValue('company_id', cs?.id);
  }, []);

  const handleCustomerChange = (customer, props) => {
    setSelectedCustomerData(customer);
    setMaxLimit(customer?.limit);
  };

  const hanldeItemsChange = (selectedOption, props) => {
    if (!selectedCustomerData) {
      toast.error(t('select_customer_before_proceeding'));
      props.setFieldValue('items', []);
      return;
    }
    const newItem = {
      id: selectedOption.value,
      quantity: 1,
      unit_price: selectedOption.amount,
      patient_share: selectedCustomerData?.patient_share ?? 0,
      discount_type: 'percentage',
      discount_amount: 20,
      tax_type: 'percentage',
      tax_amount: 15,
      tax_check: getNationality(props) === 'saudi' ? false : true,
      item_code: selectedOption?.item_code,
      item_type: selectedOption?.item_type,
      item_name: selectedOption?.label
    };
    props.setFieldValue('items', [...props.values.items, newItem]);
  };

  const getItemName = item => {
    if (item?.item_name) return item.item_name;
    return lang === 'ar' ? item?.item_name_ar : item?.item_name_en;
  };

  const getInvoiceText = () => {
    if (cancelData) return cancelStatus ? t('cancel') : t('update');
    return t('create');
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        innerRef={formikRef}
        onSubmit={async (values, { resetForm }) => {
          // delete account code key if paid is false
          if (!values?.paid) {
            delete values.account_code;
          }
          if (cancelData) {
            dispatch(
              updatePatientInvoice({
                branch_id: values?.branch_id,
                business_id: values?.business_id,
                customer_id: values?.customer_id,
                date: values?.date,
                description: values?.description,
                invoice_type: values?.invoice_type,
                invoice_ref: values?.invoice_ref,
                item_details: calculateShares(formikRef?.current, maxLimit),
                paid: values?.paid,
                ...(formikRef?.current?.values?.paid === true && {
                  account_code: values?.account_code
                }),
                deleted_items: deletedItems.flat(Infinity),
                payment_method: values?.payment_method,
                company_id: Number(license)
              })
            ).then(() => {
              resetForm();
              navigate('/manager/creditInvoiceTable');
              dispatch(getCompanyInvoicesList({ page: 1, per_page: 10 }));
            });
          } else {
            const resultAction = await dispatch(
              createCompanyInvoice({
                branch_id: values?.branch_id,
                business_id: values?.business_id,
                customer_id: values?.customer_id,
                date: values?.date,
                description: values?.description,
                invoice_type: values?.invoice_type,
                item_details: calculateShares(formikRef?.current, maxLimit),
                paid: values?.paid,
                ...(formikRef?.current?.values?.paid === true && {
                  account_code: values?.account_code
                }),
                payment_method: values?.payment_method,
                company_id: Number(license)
              })
            );
            if (createCompanyInvoice.fulfilled.match(resultAction)) {
              const Pdata = {
                date: values.date,
                amount: values.amount,
                description: values.description,
                paid: values.paid,
                customer_id: values.customer_id,
                branch_id: values.branch_id,
                business_id: values.business_id,
                invoice_type: values.invoice_type,
                items: values.items
              };
              // dispatch(createPatientInvoice(Pdata));
              //   setCreateDialogVisible(false);
              resetForm();
              navigate('/manager/insuranceInvoice');
              // dispatch(getCompanyInvoicesList({ page: 1, per_page: 10 }));
            }
          }
        }}
      >
        {props => (
          <>
            <div className='flex rounded-md bg-gray-100 p-6 w-full gap-4 max-h-[calc(100vh-150px)]'>
              <div className={` ${props?.values?.items?.length > 0 ? 'w-[70%]' : 'w-full'}`}>
                <h2 className='text-3xl font-bold mb-6 text-gray-800'>
                  {`${getInvoiceText()} ${t('company Invoice')}`}
                </h2>
                <div className='grid grid-cols-1 md:grid-cols-4 gap-4 w-full mb-3'>
                  <SharedInputText
                    type={'datetime-local'}
                    label={t('date')}
                    name={'date'}
                    placeholder='Enter Date'
                    className='!p-1.5'
                  />

                  <CustomSearchInput
                    label={t('customers')}
                    name='customer_id'
                    placeholder={`${t('select')} ${t('customers')}`}
                    className={'!w-full'}
                    isAsync={true}
                    fieldUrl={'searchCustomer'}
                    onChange={value => handleCustomerChange(value, props)}
                    optionsFormat={customersOptionsFromat}
                    defaultValue={
                      cancelData ? { label: cancelData?.customer_name, value: cancelData?.customer_id } : ''
                    }
                  />

                  <CustomSearchInput
                    label={t('payer')}
                    name='company_id'
                    placeholder={`${t('select')} ${t('payer')}`}
                    options={PayerOptions}
                    className={'!w-full'}
                    onChange={option => {
                      handleCompanySelect(option?.id);
                      setLicense(option?.license);
                    }}
                  />

                  <SharedDropdown label={t('paymentMethod')} name={'payment_method'} options={payment_method_values} />
                  <SharedDropdown label={t('paid')} name={'paid'} options={paid_values} />
                  {props?.values?.paid === true && (
                    <CustomSearchDropdown
                      label={t('account')}
                      className='!w-full'
                      name={'account_code'}
                      options={listAccountsvalues}
                      placeholder={`${t('select')} ${t('account')}`}
                    />
                  )}

                  <SharedInputText
                    label={t('description')}
                    name='description'
                    placeholder={`${t('enter')} ${t('description')}`}
                    className='!p-1.5'
                  />
                  <Field
                    name={`max_limit`}
                    as={SharedInputText}
                    label={t('maxlimit')}
                    type='number'
                    placeholder={`${t('enter')} ${t('maxlimit')}`}
                    className='!p-1.5'
                    onChange={event => {
                      const value = event.target.value;
                      props.setFieldValue(`max_limit`, value);
                      setMaxLimit(Number(value));
                    }}
                  />
                </div>

                <div className='max-h-[450px] overflow-y-auto scrollbar'>
                  {props.values?.items?.map((item, index) => (
                    <div key={index} className='grid grid-cols-1 gap-2 mb-4 border-t border-b py-4'>
                      <div className='flex gap-4 items-center'>
                        <div className='flex'>
                          <span className='font-semibold'>{t('name')}: </span>
                          <div className='px-2'>{getItemName(item)}</div>
                        </div>
                        <div className='flex items-center'>
                          <span className='font-semibold'>{t('ItemAmount')}:</span>
                          <div className='px-2'>
                            <Field
                              name={`items[${index}].unit_price`}
                              as={SharedInputText}
                              type='number'
                              className='!p-0.5'
                            />
                          </div>
                        </div>
                      </div>

                      <div className='grid grid-cols-7 gap-3'>
                        <Field
                          name={`items[${index}].quantity`}
                          as={SharedInputText}
                          label={t('quantity')}
                          type='number'
                          placeholder={`${t('select')} ${t('quantity')}`}
                          className='!p-1.5'
                        />
                        <Field
                          name={`items[${index}].patient_share`}
                          as={SharedInputText}
                          label={t('customer_share')}
                          type='number'
                          placeholder={`${t('enter')} ${t('customer_share')}`}
                          className='!p-1.5'
                          onChange={event => {
                            const value = event.target.value;
                            if (value < 0) {
                              props.setFieldValue(`items[${index}].patient_share`, 0);
                            } else {
                              props.setFieldValue(`items[${index}].patient_share`, value);
                            }
                          }}
                        />
                        <div className='flex flex-col justify-between'>
                          <label className='text-base font-medium'>{t('discountType')}</label>
                          <div className='flex w-full'>
                            <button
                              type='button'
                              onClick={() => {
                                props.setFieldValue(`items[${index}].discount_type`, 'percentage');
                              }}
                              className={`w-[50%] border !p-1.5 transition-all rounded-tl-md rounded-bl-md !border-r-0 ${
                                props.values.items[index].discount_type === 'percentage'
                                  ? '!bg-primary !border-primary text-white'
                                  : '!bg-white !border-white text-primary'
                              }`}
                            >
                              {t('percent')}
                            </button>
                            <button
                              type='button'
                              onClick={() => {
                                props.setFieldValue(`items[${index}].discount_type`, 'fixed');
                              }}
                              className={`w-[50%] border !p-1.5 !border-l-0 transition-all rounded-tr-md rounded-br-md ${
                                props.values.items[index].discount_type === 'fixed'
                                  ? '!bg-primary !border-primary text-white'
                                  : '!bg-white !border-white text-primary'
                              }`}
                            >
                              {t('fix')}
                            </button>
                          </div>
                        </div>
                        <Field
                          name={`items[${index}].discount_amount`}
                          as={SharedInputText}
                          label={t('discountAmount')}
                          type='number'
                          min={0}
                          placeholder={`${t('enter')} ${t('discountAmount')}`}
                          className='!p-1.5'
                        />

                        <div className='flex flex-col justify-between'>
                          <label className='text-base font-medium'>{t('tax')} %</label>
                          <div className='flex w-full'>
                            <button
                              type='button'
                              onClick={() => {
                                props.setFieldValue(`items[${index}].tax_check`, false);
                              }}
                              className={`w-[50%] border !p-1.5 transition-all rounded-tl-md rounded-bl-md !border-r-0 ${
                                props.values.items[index].tax_check === false
                                  ? '!bg-primary !border-primary text-white'
                                  : '!bg-white !border-white text-primary'
                              }`}
                            >
                              {t('false')}
                            </button>
                            <button
                              type='button'
                              onClick={() => {
                                props.setFieldValue(`items[${index}].tax_check`, true);
                              }}
                              className={`w-[50%] border !p-1.5 !border-l-0 transition-all rounded-tr-md rounded-br-md ${
                                props.values.items[index].tax_check === true
                                  ? '!bg-primary !border-primary text-white'
                                  : '!bg-white !border-white text-primary'
                              }`}
                            >
                              {t('true')}
                            </button>
                          </div>
                        </div>

                        <div className='flex justify-end items-end '>
                          <span className='h-[30px] w-full flex justify-end pr-5'>
                            <RiDeleteBin5Line
                              size={20}
                              className='cursor-pointer text-red-500'
                              onClick={() => {
                                if (cancelStatus) {
                                  const newItem = props.values.items.filter((_, i) => i === index);
                                  setDeletedItems(prev => [...prev, newItem]);
                                }
                                const newItems = props.values.items.filter((_, i) => i !== index);
                                props.setFieldValue('items', newItems);
                              }}
                            />
                          </span>
                        </div>
                      </div>

                      {props.errors.items && props.errors.items[index] && (
                        <div className='col-span-4 text-red-500'>
                          {Object.values(props.errors.items[index]).map((error, i) => (
                            <div key={i}>{error}</div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                  {/* {props.errors.items && typeof props.errors.items === 'string' && (
                    <div className='text-red-500'>{props.errors.items}</div>
                  )} */}
                </div>

                <div className='mt-4'>
                  <CustomSearchInput
                    label={t('items')}
                    name='items'
                    placeholder={`${t('select')} ${t('items')}`}
                    className='!w-full'
                    options={ItemsOptions}
                    onChange={selectedOption => hanldeItemsChange(selectedOption, props)}
                  />
                </div>

                <div className='flex justify-between mt-6'>
                  <div className='flex gap-6'>
                    {!cancelData && (
                      <React.Fragment>
                        <SharedButton
                          icon='pi pi-plus'
                          label={t('CREATECUSTOMERS')}
                          className='submit-btn'
                          onClick={() => navigate('/manager/customers')}
                        />
                        <SharedButton
                          icon='pi pi-plus'
                          label={`${t('create')} ${t('payer')}`}
                          className='submit-btn'
                          onClick={() => navigate('/manager/business')}
                        />
                      </React.Fragment>
                    )}
                  </div>
                  <div className='flex gap-6'>
                    <SharedButton
                      icon='pi pi-times'
                      label={t('Cancel')}
                      className='cancel-btn'
                      onClick={() => {
                        navigate('/manager/insuranceInvoice');
                        props.resetForm();
                      }}
                    />
                    <SharedButton
                      icon='pi pi-check'
                      label={isLoading ? t('Submit') + '...' : t('Submit')}
                      className='submit-btn'
                      disabled={isLoading}
                      onClick={props.handleSubmit}
                    />
                  </div>
                </div>
              </div>

              <div className={`${props?.values?.items?.length > 0 ? 'w-[30%]' : 'hidden'}`}>
                {props?.values?.items?.length > 0 && (
                  <div className='w-full bg-white rounded-lg shadow-md p-4 h-fit'>
                    <h3 className='text-xl font-medium mb-3 text-gray-800'>{t('bill_summary')}</h3>
                    <div className='space-y-2'>
                      {props?.values?.invoice_type === 'credit' && (
                        <div className='bg-gray-50 p-2 rounded-lg'>
                          <div className='flex justify-between'>
                            <h4 className='text-md font-medium text-gray-700'>{t('total_discount')}:</h4>
                            <span className='font-semibold'>
                              {calculateShares(props, maxLimit).totals?.discountedAmount}
                            </span>
                          </div>
                        </div>
                      )}
                      <div className='bg-gray-50 p-2 rounded-lg'>
                        <h4 className='text-md font-medium text-gray-700'>{t('cash_bill')}</h4>
                        <div className='space-y-1'>
                          <div className='flex justify-between'>
                            <span className='text-gray-600 text-sm'>{t('amount')}:</span>
                            <span className='font-semibold text-sm'>
                              {calculateShares(props, maxLimit).totals?.patientShareTotal}
                            </span>
                          </div>
                          {props?.values?.invoice_type === 'sales' && (
                            <div className='flex justify-between'>
                              <span className='text-gray-600 text-sm'>{t('discount')}:</span>
                              <span className='font-semibold text-sm'>
                                {calculateShares(props, maxLimit).totals?.discountedAmount}
                              </span>
                            </div>
                          )}
                          <div className='flex justify-between'>
                            <span className='text-gray-600 text-sm'>{t('tax')}:</span>
                            <span className='font-semibold text-sm'>
                              {calculateShares(props, maxLimit).totals?.patientTaxTotal}
                            </span>
                          </div>
                          <div className='flex justify-between text-md font-semibold'>
                            <span className='text-gray-600'>{t('total')}:</span>
                            <span>
                              {(
                                calculateShares(props, maxLimit).totals?.patientShareTotal +
                                calculateShares(props, maxLimit).totals?.patientTaxTotal
                              ).toFixed(2)}
                            </span>
                          </div>
                        </div>
                      </div>

                      {props?.values?.invoice_type === 'credit' && (
                        <div className='bg-gray-50 p-2 rounded-lg'>
                          <h4 className='text-md font-medium text-gray-700'>{t('company_bill')}</h4>
                          <div className='space-y-1'>
                            <div className='flex justify-between'>
                              <span className='text-gray-600 text-sm'>{t('amount')}:</span>
                              <span className='font-semibold text-sm'>
                                {calculateShares(props, maxLimit).totals?.companyShareTotal}
                              </span>
                            </div>
                            <div className='flex justify-between'>
                              <span className='text-gray-600 text-sm'>{t('tax')}:</span>
                              <span className='font-semibold text-sm'>
                                {calculateShares(props, maxLimit).totals?.companyTaxTotal}
                              </span>
                            </div>
                            <div className='flex justify-between text-md font-semibold'>
                              <span className='text-gray-600'>{t('total')}:</span>
                              <span>
                                {(
                                  calculateShares(props, maxLimit).totals?.companyShareTotal +
                                  calculateShares(props, maxLimit).totals?.companyTaxTotal
                                ).toFixed(2)}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <Loader showLoader={isLoading} />
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </Formik>
    </>
  );
}
