import i18next from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function AccountStatementTable({ data }) {
  const lang = i18next.language;
  const indexedData = data?.data ? data?.data?.map((item, index) => ({ ...item, index: index + 1 })) : [];
  const { t } = useTranslation();

  if (!indexedData || !indexedData) {
    return <div>No Statement data available</div>;
  }

  const calculateTotals = () => {

    let totalDebit = data?.total_debit;
    let totalCredit = data?.total_credit;
    let netTotal = data?.total_balance;

    return {
      totalDebit: totalDebit,
      totalCredit: totalCredit,
      netTotal: netTotal
    };
  };

  const totals = calculateTotals();

  let runningBalance = 0;

  return (
    <>
      <div className='bg-white !rounded-tl-md !rounded-tr-md'>
        <div className='grid grid-cols-12 bg-secondary font-bold py-1 !rounded-tl-md !rounded-tr-md'>
          <div className='col-span-1 px-1.5'>{t('no')}</div>
          <div className='col-span-1'>{t('date')}</div>
          <div className='col-span-3'>{`${t('account')}`}</div>
          <div className='col-span-1'>{`${t('sub_type')}`}</div>
          <div className='col-span-2'>{`${t('description')}`}</div>
          <div className='col-span-1'>{`${t('debit')} ${t('amount')}`}</div>
          <div className='col-span-1'>{`${t('credit')} ${t('amount')}`}</div>
          <div className='col-span-2'>{`${t('balance')}`}</div>
          {/* <div className="col-span-4">{`${t("description")}`}</div> */}
        </div>
        {indexedData?.map(asset => {
          if (asset.transaction_total_debit >0 ) {
            runningBalance += parseFloat(asset.transaction_total_debit) || 0;
          }
          if (asset.transaction_total_credit >0 ) {
            runningBalance -= parseFloat(asset.transaction_total_credit) || 0;
          }

          return (
            <div key={asset.id} className='grid grid-cols-12 items-center py-1 border-b last:border-b-0'>
              <div className='col-span-1 px-1.5'>{asset.index}</div>
              <div className='col-span-1'>{asset.transaction_date}</div>
              <div className='col-span-3'>
                {asset.account_code}-{lang === 'ar' ? `${asset.account_name_en}` : `${asset.account_name_ar}`}
              </div>

              <div className='col-span-1'>
                {lang === 'ar' ? `${asset.account_sub_type_ar}` : `${asset.account_sub_type_en}`}
              </div>

              <div className='col-span-2'>{asset?.entry_description}</div>

              <div className='col-span-1'>
                {asset.transaction_total_debit} {`${t('SAR')}`}
              </div>

              <div className='col-span-1'>
                {asset.transaction_total_credit} {`${t('SAR')}`}
              </div>

              <div className='col-span-2 balance'>
                {/* {(asset.transaction_total_debit)+(-(asset.transaction_total_credit))}{" "} */}
                {runningBalance.toFixed(2)} {`${t('SAR')}`}
              </div>

              {/* <div className="col-span-4">
              {asset.account_description}
            </div> */}
            </div>
          );
        })}
        {/* Totals */}
        <div className='grid grid-cols-12 items-center py-1 border-t bg-gray-100'>
          <div className='col-span-1 px-1.5'></div>
          <div className='col-span-5'></div>
          <div className='col-span-1 font-semibold text-black/70 text-lg'>{t('Total')}</div>
          <div className='col-span-1'></div>
          <div className='col-span-1 font-semibold text-black/60 text-lg'>
            {totals.totalDebit} {`${t('SAR')}`}
          </div>
          <div className='col-span-1 font-semibold text-black/60 text-lg'>
            {totals.totalCredit} {`${t('SAR')}`}
          </div>
          <div className='col-span-1 font-semibold text-black/60 text-lg'>
            {totals.netTotal} {`${t('SAR')}`}
          </div>
        </div>
      </div>
    </>
  );
}
